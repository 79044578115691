@import "../../App.css";

.preview-container {
    background-color: var(--color-dark);
    text-align: left;
    width: 100%;
    position: absolute;
    margin-top: -4%;
}

.preview-container-mobile {
    background-color: var(--color-dark);
    text-align: left;
    width: 100%;
    position: absolute;
    margin-top: -6.5rem;
}

.preview-items {
    margin-top: 5%;
    margin-bottom: 5%;
    overflow:visible;
}

.Button {
    color: var(--color-light);
}

.works-title-wrapper .wrapper {
    display: inline-flex;
    margin-top: 15%;
    margin-bottom: -10%;
    overflow: hidden;
}

.works-title-wrapper-mobile .wrapper {
    display: inline-flex;
    margin-top: 80vh;
    margin-bottom: -10%;
    overflow: hidden;
}

.works-title h1 {
    color: var(--color-dark);
    font-size: 10vw;
    font-family: var(--font-secondary);
}

.works-title svg {
    fill: var(--color-dark);
    height: 25vw;
    margin-top: -4%
}

.works-title-mobile h1 {
    color: var(--color-dark);
    font-size: 9rem;
    font-family: var(--font-secondary);
}

.works-title-mobile svg {
    fill: var(--color-dark);
    height: 24rem;
    margin-top: -6rem;
}