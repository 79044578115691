@import "../../App.css";

body {
  overflow-x: hidden;
}

.about-me-container {
  margin-top: -300px;
  background-color: var(--color-light);
  text-align: center;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  width: 250%;
  position: absolute;
  height: 200vh;
  left: 50%;
  transform: translateX(-50%);
  z-index: -3;
}

.about-me-container-mobile {
  margin-top: -150px;
  background-color: var(--color-light);
  text-align: center;
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
  width: 250%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.fadein-container {
  width: 100%;
  font-family: var(--font-main);
}

.fadein-container>p {
  text-align: left;
  color: dimgrey;
}

.body-text-container {
  float: left;
  margin: 0 35% 0 35%;
  height: 50vh;
}

.body-text-container-mobile {
  float: left;
  margin: 0 35% 0 35%;
  height: 50vh;
}

.body-text {
  text-align: left
}

.body-text p,
i {
  color: var(--color-p);
}

.body-text h2 {
  margin: 0;
  font-size: xx-large;
  color: var(--color-p);
}

.title {
  display: inline-flex;
  text-align: center;
}

.title h1 {
  font-size: 10vw;
  margin-bottom: -10px;
  color: var(--color-h1);
}

.title svg {
  height: 11vw;
  margin-top: 10%;
}

.title-mobile {
  text-align: center;
  margin-top: -8%;
}

.title-mobile h1 {
  font-size: 4rem;
  margin-top: -20px;
  margin-bottom: -20px;
  color: var(--color-h1);
}

.title-mobile svg {
  height: 4rem;
  margin-top: -10px;
}