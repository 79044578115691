.cursor {
    background-color: var(--color-light);
    height: 32px;
    width: 32px;
    border-radius: 50%;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .scroll-cover {
    position: absolute;
    height: 120vh;
    width: 100%;
    margin-top: -25vh;
    z-index: 99;
  }