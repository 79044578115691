* {
	cursor: none;
}

:root {
	--color-dark: #1f1e1c;
	--color-light: #f9f0e8;
	--color-h1: cadetblue;
	--color-h2: darkslategray; 
	--color-h3: #9dc6c7;
	--color-p: #8c8782;
	--color-i: #b8b3aa;
	--font-main: Lexend;
	--font-secondary: Staatliches;
}

.container-side-by-side {
    display: flex; 
	flex-direction: row; 
	flex-wrap: wrap; 
}