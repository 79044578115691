@font-face {
  font-family: "Lexend";
  src: local("Lexend"), url(./fonts/Lexend-VariableFont_wght.ttf) format("truetype")
}

@font-face {
  font-family: "Staatliches";
  src: local("Staatliches"), url(./fonts/Staatliches-Regular.ttf) format("truetype")
}

::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f9f0e8;
  overflow-x: hidden;
  width: 100%;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#container {
  overflow-x: hidden;
  width: 100%;
}

#main {
  top: 0;
}