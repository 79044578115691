.info-section {
    position: relative;
}

.info-wrapper {
    width: 100%;
    position: absolute;
    bottom: 0;
}

.wrapper {
    margin: 2rem;
}

.info-wrapper h1 {
    font-size: xx-large;
    margin-bottom: -20px;
    color: var(--color-light);
}

.info-wrapper h2 {
    font-size: x-large;
    font-weight: normal;
    color: var(--color-light);
}

.info-wrapper p {
    font-size: larger;
    color: var(--color-light);
}