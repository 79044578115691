img.banner-image {
    height: 100vh;
}

.banner-wrapper {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    z-index: -1;
}

.banner-wrapper .wrapper {
    display: flex;
}

.banner-wrapper .wrapper .images {
    display: flex;
    /* animation: swipe var(--speed) linear infinite; */
}

@keyframes swipe {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}