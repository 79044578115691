@import "../../App.css";

img.hero {
    object-fit: cover;
    width: 100vw;
    height: 110vh;
    z-index: -1;
}

video.hero{
    object-fit: cover;
    height: 100vh;
    width: 100vw;
    z-index: -1;
}

.hero-container {
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    object-fit: contain;
}

.hero-container > h1 {
    color: var(--color-light);
    font-size: 100px;
    margin: -25px;
    font-family: Lexend;
}

.hero-container > p {
    color: var(--color-light);
    font-size:25px;
    margin-bottom: 10px;
    font-family: var(--font-main);
}

.triangle-container {
    position: absolute;
    bottom: 5%;
}

.triangle-down {
    width: 0;
	height: 0;
	border-left: 25px solid transparent;
	border-right: 25px solid transparent;
	border-top: 25px solid var(--color-light);
    border-radius: 5px;
}